*,
body {
  margin: 0;
  padding: 0;
}

.navbar {
  width: 100%;
  height: 10vh;
  box-sizing: border-box;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1vw 1vw;
}

.navbar .logo {
  width: 20vw;
  height: 4.2vw;
  display: flex;
  align-items: center;
  justify-content: start;
}

.navbar .logo img {
  width: 7.5vmax;
  height: 4vmax;
}

.navbar .text {
  width: 60vw;
  height: 4.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: #e73437;
  font-size: 3vmax;
  font-weight: 700;
  cursor: pointer;
}

.navbar .empty-space {
  width: 20vw;
  height: 4.2vw;
  display: flex;
  justify-content: end;
  align-items: center;
}

.navbar .empty-space img {
  width: 3.12vmax;
  height: 3.14vmax;
  cursor: pointer;
}

.navbar .button-container {
  position: absolute;
  right: 1vw;
  top: 5.3vw;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.navbar .button-container button {
  width: 12.75vmax;
  height: 4vmax;
  color: white;
  background-color: #f6f6f6;
  border-radius: 0.5vmax;
  border: none;
  color: #000;
  font-size: 1.2vmax;
  font-weight: 600;
  cursor: pointer;
}

.navbar .button-container .logout-button {
  width: 12.75vmax;
  height: 4vmax;
  color: white;
  background-color: #e73437;
  border-radius: 0.5vmax;
  border: none;
  font-size: 1.2vmax;
  font-weight: 600;
  cursor: pointer;
}

.navbar .button-container .logout-button:hover {
  background-color: #e31b1e;
}

.navbar .button-container button:hover {
  background-color: #b8b8b8;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 50;
  cursor: not-allowed;
}

.content-blur {
  filter: blur(5px);
  pointer-events: none;
}

@media (max-width: 600px) {
  .navbar .button-container {
    position: absolute;
    right: 0.4vmax;
    top: 10.3vmax;
  }

  .navbar .button-container button {
    width: 45vw;
    height: 5vh;
    color: white;
    background-color: #d2d2d2;
    border-radius: 0.5vmax;
    border: none;
    color: #000;
    font-size: 1.2vmax;
    font-weight: 800;
    cursor: pointer;
  }

  .navbar .button-container .logout-button {
    width: 45vw;
    height: 5vh;
    font-weight: 800;
    font-size: 2vh;
  }

  .navbar .button-container button {
    font-size: 2vh;
  }
}
