.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
  width: 100%;
  box-sizing: border-box;
}

.project-container .project-details {
  width: 90vw;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.project-details .left,
.project-details .right {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.project-details .left {
  width: 56%;
  box-sizing: border-box;
}

.project-details .right {
  width: 40%;
  box-sizing: border-box;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  box-sizing: border-box;
}

.project-details input,
.project-details select {
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  outline: none;
}

.input-fields {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.two-cols {
  display: flex;
  justify-content: space-between;
  gap: 2%;
}

.two-cols .input-fields {
  width: 48%;
  box-sizing: border-box;
}

.image-container {
  width: 100%;
  height: 170px;
  background-color: rgb(214, 211, 211);
  font-size: 25px;
  font-weight: 700;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

select option {
  font-size: 18px;
  border-radius: 4px;
}

.project-summary {
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin-top: 3vw;
}

.project-summary h1 {
  font-size: 20px;
  margin-bottom: 1.2vw;
}

.project-summary .upper,
.project-summary .middle {
  display: flex;
  gap: 2.5vw;
  width: 100%;
}

.project-summary .input-fields {
  flex: 1;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.project-summary input {
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

#checkbox {
  margin-top: 10px;
  height: 25px;
}

.table {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.data-table {
  width: 70%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  border: 1px solid #ccc;
  font-size: 18px;
  padding: 4px;
  text-align: center;
}

.data-table thead {
  background-color: #f4f4f4;
}

.data-table input {
  width: 100%;
  padding: 10px;
  border: none;
}

.amenities-booking {
  height: 650px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.amenities_listing {
  height: 100%;
  width: 60%;
  box-sizing: border-box;
}

.amenities-heading,
.booking-heading,
.feature-heading {
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 10px;
}

.amenities_listing .amenites {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.amenities_listing .amenites .col1,
.amenities_listing .amenites .col2 {
  width: 30%;
  margin-right: 10px;
  box-sizing: border-box;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 10px;
}

.amenities_listing .amenites .col3 {
  width: 30%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 10px;
}

.amenities-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.amenities-checkbox input {
  width: 30px;
  height: 20px;
}

.amenities_listing .amenites .col3 textarea {
  resize: none;
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  font-size: 18px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
}

.booking-offers {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.booking-offers .booking-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 20px;
  align-items: center;
}

.booking-offers .booking-content button {
  height: 70px;
  width: 80%;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
}

.booking-offers .booking-content textarea {
  height: 170px;
  box-sizing: border-box;
  width: 80%;
  outline: none;
  font-size: 18px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  resize: none;
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
}

.project-container .features {
  display: flex;
  flex-direction: column;
  width: 90vw;
}

.project-container .features .textbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4vh;
}

.project-container .features .textbox textarea {
  resize: none;
  width: 48%;
  height: 30vh;
  box-sizing: border-box;
  border: 2px solid #ccc;
  padding: 10px;
  font-size: 18px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  border-radius: 10px;
}

.project-container .features .features-nearby {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 2.5%;
  margin-bottom: 8vh;
}

.project-container .features .features-nearby .input-fields {
  width: 23%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.project-container .features .features-nearby .input-fields input {
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  outline: none;
  font-size: 18px;
}

.finance-floorplans {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.finance-floorplans .floor-plan {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.finance-floorplans .input-fields {
  width: 23%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
}

.finance-floorplans .input-fields input {
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  outline: none;
  font-size: 18px;
}

.finance-floorplans button {
  background-color: transparent;
  border: 1px solid #ccc;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin: 0px 10px;
}

.btn-add,
.btn-delete {
  width: 250px;
  height: 72px;
  border-radius: 8px;
  background-color: #e73437;
  color: white;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
}

.btn-add {
  margin-top: 7vh;
  
}

.btn-delete {
  margin-bottom: 5vw;
}

.reraNumber-color::placeholder {
  color: red;
}

.rating {
  width: 100vw;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

.star-container {
  height: 100px;
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.star-container img {
  height: 60px;
}

#required {
  color: red;
  font-size: 15px;
}

#error {
  color: red;
}

.floor-plan-slideshow {
  width: 500px;
  height: 300px;
  box-sizing: border-box;
}

.floor-plan-slideshow .each-slide-effect div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
  box-sizing: border-box;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.booking-content .booking-offer-image {
  width: 80%;
  height: 200px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .project-container .project-details {
    flex-direction: column;
    margin-bottom: 8vh;
  }

  .project-container .project-details .left {
    width: 100%;
  }

  .project-container .project-details .left .two-cols {
    flex-direction: column;
  }

  .project-container .project-details .left .two-cols .input-fields {
    width: 100%;
  }

  .project-container .project-details .right {
    width: 100%;
  }

  .project-container .project-details .right .two-cols {
    flex-direction: column;
  }

  .project-container .project-details .right .two-cols .input-fields {
    width: 100%;
  }

  .project-container .project-summary h1,
  .project-container .features h1 {
    background-color: red;
    text-align: center;
    padding: 10px 0px;
    color: white;
    border-radius: 10px;
    margin-bottom: 5vh;
  }

  .project-container .project-summary .upper {
    flex-direction: column;
  }

  .project-container .project-summary .upper #not-applicable {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 70px;
  }

  .project-container .project-summary .upper #not-applicable label {
    width: 60%;
  }

  .project-container .project-summary .upper #not-applicable input {
    width: 30px;
  }

  .project-container .project-summary .middle {
    flex-direction: column;
  }

  .project-container .project-summary .table .data-table {
    width: 100%;
  }

  .project-container .project-summary .amenities-booking {
    flex-direction: column;
    height: 1500px;
  }

  .project-container .project-summary .amenities-booking .amenities_listing {
    width: 100%;
  }

  .project-container .project-summary .amenities-booking .amenities_listing .amenites {
    flex-direction: column;
  }

  .project-container .project-summary .amenities-booking .amenities_listing .amenites .col1,
  .project-container .project-summary .amenities-booking .amenities_listing .amenites .col2,
  .project-container .project-summary .amenities-booking .amenities_listing .amenites .col3 {
    width: 100%;
    margin-bottom: 5vh;
  }

  .project-container .project-summary .amenities-booking .amenities_listing .amenites .col3 textarea {
    height: 40vh;
    padding: 10px;
  }

  .project-container .project-summary .amenities-booking .booking-offers {
    width: 100%;
    margin-top: 25vh;
  }

  .project-container .project-summary .amenities-booking .booking-offers .booking-offer-info,
  .project-container .project-summary .amenities-booking .booking-offers button {
    width: 100%;
  }

  .project-container .features {
    margin-top: 120vh;
  }

  .project-container .features .textbox {
    display: flex;
    flex-direction: column;
  }

  .project-container .features .textbox textarea {
    width: 100%;
    margin-bottom: 3vh;
  }

  .feature-heading {
    font-size: 22px;
  }

  .project-container .features .features-nearby {
    display: flex;
    flex-direction: column;
  }

  .project-container .features .features-nearby .input-fields {
    width: 100%;
  }

  .project-container .features .finance-floorplans {
    display: flex;
    flex-direction: column;
  }

  .project-container .features .finance-floorplans .input-fields {
    width: 100%;
  }

  .project-container .features .finance-floorplans .floor-plan {
    width: 100%;
    padding: 5vh 0px;
  }

  .project-container .btn-add,
  .project-container .btn-delete {
    width: 90%;
    height: 50px;
    font-size: 22px;
  }

  .star-container {
    width: 100%;
    padding: 0px 20px; 
    border: 3px solid gray;
    margin: 0px 15px;
    border-radius: 10px;
  }

  .star-container img {
    width: 40px;
  }

  .finance-floorplans {
    align-items: center;
  }

  .floor-plan-slideshow {
    width: 95vw;
    height: 200px;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .floor-plan-slideshow .each-slide-effect div {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 200px;
    box-sizing: border-box;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
}