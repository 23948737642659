.buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.buttons button {
    height: 70px;
    width: 260px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 27px;
    font-weight: bold;
}

.buttons button:hover {
    background-color: rgb(201, 33, 33);
    transition: 0.3s;
}